import { ArticleEndPoint } from '@/Models/ArticleEndPoint'
import { Component, Prop, Vue } from 'vue-property-decorator'
import articleModule from '@/store/moduleArticle'
import authModule from '@/store/auth'

@Component
export default class ArticleCards extends Vue {
  @Prop({ required: false })
  article!: ArticleEndPoint
  articleDate = ''

  user: any = {}
  userGroups: any = []

  objEdit: ArticleEndPoint = new ArticleEndPoint()
  image =
    'https://st.depositphotos.com/1010613/3178/i/600/depositphotos_31785671-stock-photo-woman-looking-at-news-article.jpg'

  async mounted() {
    this.articleDate = new Date(this.article.created_at)
      .toISOString()
      .split('T')[0]

    this.user = authModule.user
    this.userGroups = this.user.groups.map((group: any) => group.name)
    if (this.article.image) this.image = this.article.image
  }

  toArticle(article: any) {
    articleModule.setArticle(article)
    this.$router.push('/articleDetail')
  }

  deleteArticle() {
    this.$emit('deleteArticle', this.article)
  }
  updateArticle() {
    this.$emit('updateArticle', this.article)
  }
}
